.mobile-container {
  margin: 0 24px;
}

.results-black-november-banner {
  margin-bottom: 24px;

  @media (min-width: 797px) {
    margin-bottom: 60px;
  }
}

.body-locked {
  .selectable-list-counter .filter-fade {
    background-image: none;
  }
}

.body-locked {
  .body {
    overflow: hidden;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $mobile-m) {
    max-width: 175px;
  }

  @media (min-width: $desktop) {
    max-width: 100%;
  }
}

.results-page {
  .results-breadcrumb {
    padding-top: 0;
  }
  .results-container {
    padding: 0px;

    .info-header {
      border-top: 0px;
    }

    @media (max-width: $tablet-mid-1) {
      margin-top: 1rem;
      margin-left: 0;
      padding-top: 0;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: normal;
    color: #343a42;

    @media (min-width: 797px) {
      margin-bottom: 24px;
    }
  }

  @media (min-width: $tablet-min) and (max-width: $desktop) {
    &--new {
      .content-placeholder {
        padding-right: 1rem;
      }
    }
  }
}

.search-container--product-card {
  hot-card {
    border-radius: 0.75rem !important;
  }
}

.results-page {
  .body,
  .body__content {
    background-color: var(--white);
  }

  .page-container__content {
    @media (max-width: $mobile-max) {
      padding: 0 0.5rem;
    }
  }
}

.results-searchbar.category-search {
  margin: 1.5rem auto 2rem auto;
}

.body-blocker {
  height: 100vh;
  overflow: hidden;
}

.no-scroll {
  overflow: hidden;
}

.lowercase {
  text-transform: lowercase;
}

.plus-btn {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $desktop-m) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: $desktop) {
    width: 46px;
    height: 46px;
  }

  .icon {
    color: #fff;
    width: 20px;

    @media (max-width: $desktop) {
      width: 16px;
    }
  }
}
// GLOBAL ----------------------------------------------------------------------------------------------

a,
input,
select,
textarea,
button {
  &:hover,
  &:active {
    outline: 0 none;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

header,
.main {
  float: left;
  width: 100%;
}

body {
  margin: 0;
  max-width: 100%;
}

.colorBg {
  background-color: var(--color-theme);
}

.colorBgHover:hover {
  background-color: var(--color-theme);
}

.colorBgHoverAfter:hover:after {
  background-color: var(--color-theme);
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
  margin: 0;
}

.content-main {
  overflow: auto;
}

body {
  background-color: var(--gray-100);
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.body--new {
    background-color: var(--white);

    .hm-mktplace-footer {
      background-color: var(--white) !important;
    }
  }
}

p {
  font-weight: 300;
  font-size: 14px;
  color: $text;
  line-height: 20px;
  transition-delay: 400ms;
  pointer-events: all;
  margin: 0 0 20px;
}

#__next {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

a {
  color: inherit;
}

.column {
  p {
    text-align: left;
    line-height: 26px;
    font-size: 16px;
  }
}

.body-container {
  #hm-mktplace-search-bar {
    position: relative;
    z-index: 100;
  }

  .hm-mktplace-header__container--center {
    @media (min-width: $desktop-m) {
      max-width: 1260px;
      margin: 0 auto;
    }
  }

  .hm-mktplace-header__options--user {
    right: 0px;
  }

  .hm-mktplace-search__container__header {
    @media (max-width: $mobile-l) {
      box-shadow: none;
      border-bottom: 1px solid #e6e9ed;
      padding: 0.75rem 0.5rem;
    }
  }

  .hm-mktplace-menu__menu-content {
    .slick-arrow-buttons {
      &__left svg {
        top: 0.1rem;
        left: auto;
      }

      &.review-right {
        svg {
          top: 0.55rem;
        }
      }

      svg {
        margin: 0 0 1rem !important;
      }
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        &.review-right {
          svg {
            top: 10px;
          }
        }

        &__left {
          top: 3px;
        }
      }
    }
  }

  .hm-mktplace-header__container {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hot-form--custom .hot-form__input:hover ~ .hot-form__label::before {
  box-shadow: 0 0 0 0.16rem #bed1ff !important;
}

.topification--title {
  &:hover {
    color: var(--brand-primary) !important;
  }
}

.topification {
  &__list {
    padding: 0px;
    list-style: none;

    &--container {
      display: grid;
      grid-template-columns: 1fr;

      @media screen and (min-width: $desktop) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: var(--spacer-10);
      }
    }

    &-item > a {
      text-decoration: none;

      &:hover {
        color: var(--brand-primary) !important;
      }
    }
  }
}

:root,
:host {
  --collapse-padding: 0.5rem 0;
}

._font-weight-extra-bold {
  font-weight: 800;
}

// Enable Optimizely test
.active-test {
  #organic-signup,
  #best-rated-card-signup,
  #signup-banner,
  #category-card-signup {
    display: block !important;
  }

  .body-black-november {
    .page-container {
      &.search__fixed-banners {
        margin-top: 0;
      }
    }
  }

  .search__sell {
    display: none !important;
  }
}
