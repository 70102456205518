@import 'breakpoints';
@import 'fonts.scss';
@import 'colors.scss';
@import 'animations.scss';
@import 'spacings.scss';
@import 'keyframes.scss';
@import 'variables.scss';
@import 'season.scss';

@import './global/carousel.scss';

//Components
@import '../../components/Avatar/style.scss';
@import '../../components/Banner/BlackNovember/style.scss';
@import '../../containers/Season/season.scss';
@import '../../components/Banner/CarouselBanner/style.scss';
@import '../../components/Banner/CentralBanner/style';
@import '../../components/Banner/SeasonBanner/style.scss';
@import '../../components/Banner/SeasonCentralBanner/style.scss';
@import '../../components/Banner/SeasonSmallBanner/style.scss';
@import '../../components/Banner/Signup/style.scss';
@import '../../components/Banner/FixedCampaignBanner/style.scss';
@import '../../components/BlackNovember/CreatorsList/style.scss';
@import '../../components/BlackNovember/DiscountBanner/style.scss';
@import '../../components/BlackNovember/HomeBanner/style.scss';
@import '../../components/Body/style.scss';
@import '../../components/Bubble/style.scss';
@import '../../components/Bubble/Category/style.scss';
@import '../../components/Button/Icon/style.scss';
@import '../../components/Button/Buy/style.scss';
@import '../../components/Breadcrumb/style.scss';
@import '../../components/Card/CategoryAccordion/style.scss';
@import '../../components/Card/CategorySignup/style.scss';
@import '../../components/Card/Discount/style.scss';
@import '../../components/Card/Display/style.scss';
@import '../../components/Card/MoreResults/style.scss';
@import '../../components/Creator/style.scss';
@import '../../components/Card/Product/style.scss';
@import '../../components/Card/ProductAlt/style.scss';
@import '../../components/Card/ProductMini/style.scss';
@import '../../components/Card/Signup/style.scss';
@import '../../components/Card/SparkleCommunity/style.scss';
@import '../../components/Card/SparkleCommunityAlt/style.scss';
@import '../../components/Card/Tile/style.scss';
@import '../../components/Carousel/style.scss';
@import '../../components/CarouselList/Product/style.scss';
@import '../../components/CategoryHeader/style.scss';
@import '../../components/CategorySearch/style.scss';
@import '../../components/Checkbox/style.scss';
@import '../../components/Dropdown/style.scss';
@import '../../components/EmptyState/Results/style.scss';
@import '../../components/EmptyState/Season/style.scss';
@import '../../components/EmptyState/User/style.scss';
@import '../../components/Filter/Category/style.scss';
@import '../../components/Filter/CustomCoupon/style.scss';
@import '../../components/Filter/CouponDiscount/style.scss';
@import '../../components/Filter/FilterSelect/style.scss';
@import '../../components/Filter/Format/style.scss';
@import '../../components/Filter/FilterRow/style.scss';
@import '../../components/Filter/Ordering/style.scss';
@import '../../components/Filter/Rating/style.scss';
@import '../../components/Footer/Common/style.scss';
@import '../../components/Grid/style.scss';
@import '../../components/Header/Common/style.scss';
@import '../../components/Header/Season/style.scss';
@import '../../components/Header/SeasonAbout/style.scss';
@import '../../components/Images/Product/style.scss';
@import '../../components/Images/Display/style.scss';
@import '../../components/Images/Hotmarter/style.scss';
@import '../../components/Link/Icon/style.scss';
@import '../../components/LinkArrow/style.scss';
@import '../../components/Link/Optional/style.scss';
@import '../../components/List/Display/style.scss';
@import '../../components/List/Product/style.scss';
@import '../../components/Loader/style.scss';
@import '../../components/Loading/style.scss';
@import '../../components/Modal/Filter/style.scss';
@import '../../components/Modal/ModalSignup/style.scss';
@import '../../components/Modal/ModalSidebar/style.scss';
@import '../../components/Nav/style.scss';
@import '../../components/NotFound/style.scss';
@import '../../components/PageContainer/style.scss';
@import '../../components/PercentageBar/style.scss';
@import '../../components/Price/style.scss';
@import '../../components/Rating/style.scss';
@import '../../components/ResultsFor/style.scss';
@import '../../components/Select/style.scss';
@import '../../components/SelectableList/style.scss';
@import '../../components/SelectableListCounter/style.scss';
@import '../../components/SuggestionsList/style.scss';
@import '../../components/Popover/style.scss';
@import '../../components/Tooltip/style.scss';
@import '../../components/Tag/style.scss';
@import '../../components/Video/style.scss';
@import '../../components/ViewToggle/style.scss';
@import '../../components/Categories/styles.scss';
@import '../../components/ModalCosmos/style.scss';
@import '../../components/CreatorModal/style.scss';
@import '../../../s/static/styles/black-november.css';

// @core
@import '@core/presentation/components/page-container/style.scss';
@import '@core/presentation/components/content-collapsed/style.scss';
@import '@core/presentation/components/content-tags/style.scss';
@import '@core/presentation/components/show-more-button/style.scss';
@import '@core/presentation/components/product-card/style.scss';
@import '@core/presentation/components/carousel/style.scss';
@import '@core/presentation/components/nav/style.scss';
@import '@core/presentation/components/content-loader/style.scss';

//Containers
@import '../../containers/Results/style.scss';
@import '../../containers/SearchOthersCategory/style.scss';
@import '../../containers/SeasonAbout/style.scss';
@import '../../containers/Category/style.scss';
@import '../../containers/Search/style.scss';

@import 'global.scss';
