@import './season-regular.scss';

.search-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--relative {
    position: relative;
    z-index: 10;
  }

  .search__category-search {
    width: 100%;
    max-width: 816px;
    margin: 0 auto;

    &__black-november {
      width: 100%;
      background-color: #181c1f;
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      height: 180px;
      display: flex;
      align-items: center;

      .black-november-banner-large__top__content {
        &__logo {
          max-width: 290px;
        }

        &__text {
          margin-bottom: 1.5rem;
        }
      }

      @media (max-width: $mobile-m) {
        min-height: 146px;
        height: auto;
        padding: 1rem;
      }
    }
  }

  .search__category__banner {
    &--cyber-week {
      background-color: #fe734f;
      &__container {
        width: 80%;
        margin: 0 auto;
      }

      &__text {
        @media (min-width: $mobile) {
          min-width: 288px;
          min-height: 121.59px;
        }

        .black-november-banner-large__top__content__text {
          span {
            height: 60px;
            overflow: hidden;
          }
        }
      }

      &__logo {
        @media (min-width: $mobile) {
          width: 260px;
          margin: 0;
        }

        @media (min-width: $desktop) {
          width: 360px;
          margin: 0 2rem;
        }
      }
    }
  }

  .search__category-black-november {
    width: 100%;
  }

  .search {
    &__banner {
      background-size: cover;
      width: 100%;
      max-width: 1440px;

      @media (min-width: $desktop) {
        height: 330px;
      }

      & > .page-container__content {
        max-width: 1440px !important;
      }

      .hm-mktplace-search__autocomplete--show-suggestion {
        .hm-mktplace-search__autocomplete__data {
          width: 800px !important;
        }

        .hm-mktplace-search__autocomplete__data--horizontal {
          width: 458px !important;
          margin-left: 1px;
        }
      }

      &__title {
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        color: var(--white);
        margin-bottom: 1.2rem;

        span {
          font-weight: bold;
          line-height: 2.4rem;
        }

        div {
          font-weight: normal;
        }

        &-wrapper {
          max-width: 1260px !important;
          margin: 0 auto;
          padding: 3rem 5rem;

          @media (max-width: $desktop-min) {
            padding: 2rem 5rem;
          }
        }
      }

      &-image {
        object-fit: cover;
        object-position: top;
        &__wrapper {
          position: absolute;
          z-index: 0;

          & > div {
            @media (min-width: $mobile) {
              height: 120px;
            }

            @media (min-width: $desktop) {
              height: 330px;
            }
          }

          &:before {
            content: '';
            background: linear-gradient(
              270deg,
              rgba(25, 28, 31, 0.1) 23.5%,
              rgba(0, 0, 0, 0.14) 71.04%
            );
            width: 100%;
            top: 0;
            position: absolute;
            z-index: 1;

            @media (min-width: $desktop) {
              height: 330px !important;
            }
          }

          span {
            @media (min-width: $desktop) {
              height: 330px !important;
            }
          }
        }
      }
    }

    &__bar {
      &-mobile {
        display: none;

        .suggestions-list {
          padding: 0 16px;
        }

        .hm-mktplace-search-bar {
          padding: 1rem;
        }
      }

      button {
        border-left: 1px solid #e6e9ed;
      }
    }

    &__header {
      font-size: 1rem;
      font-style: normal;
      font-weight: normal;
      padding: 1rem 0 0 1rem;
      line-height: 1.5rem;
    }

    &__container {
      width: 521px;
      background: rgba(89, 129, 227, 0.84);
      box-shadow: 0px 8px 16px -6px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      padding: 2rem;

      .hm-mktplace-search__autocomplete__input__field {
        &::placeholder {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #707780;
        }
      }

      .suggestions-list {
        margin-top: 1rem;
      }

      &--light {
        background: rgba(255, 255, 255, 0.81);

        .search__banner__title {
          color: var(--black);
        }

        .hm-mktplace-search__autocomplete {
          box-shadow: none;

          &__input {
            border-radius: 6px;

            input {
              border-radius: 6px;
            }

            &__field {
              &::placeholder {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: var(--gray-500);
              }
            }
          }
        }
      }
    }

    @media (max-width: $desktop) {
      &__banner {
        background-size: cover;
        height: auto;

        & > .page-container__content {
          padding: 0;
          height: 125px;
        }

        &__header {
          span {
            font-weight: bold;
          }
        }

        .hm-mktplace-search__autocomplete--show-suggestion {
          .hm-mktplace-search__autocomplete__data {
            width: auto !important;
          }
        }

        &__title {
          font-size: 1rem;
          width: auto;
          font-style: normal;
          font-weight: normal;
        }
      }

      &__container {
        display: none;
      }

      &__bar {
        display: none;

        &-mobile {
          display: initial;
          min-height: 168px;
          background: #464b52;
          box-shadow: 0px 8px 16px -6px rgba(0, 0, 0, 0.25);
          color: #ffffff;
          width: inherit;
          margin-top: 5rem;
        }
      }
    }

    @media (max-width: $tablet-min) {
      &__bar-mobile {
        margin-top: 0;
      }
    }
  }

  .black-november-banner-large {
    margin-top: 64px;
    width: 100%;
  }

  .hm-mktplace-search-input-autocomplete {
    height: 48px;
  }

  .displays-highlighted {
    border-radius: 5px;
    margin-bottom: 20px;

    .display-list-content {
      margin: 0 (-$list-gutter);
    }
  }

  .search-left {
    display: flex;
    flex-wrap: wrap;
  }

  .search-section {
    max-width: 670px;
    width: 100%;

    #hm-mktplace-search-bar {
      width: 631px;
    }
  }

  .search__section {
    max-width: 1256px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__category-list {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 0 0 16px;
      margin: -16px -16px 0 -16px;

      @media (max-width: $desktop-m) {
        padding-left: 1.5rem;
      }

      &__card {
        max-width: 33.33%;
        flex: 0 0 33.33%;
        padding: 0 16px 16px 0;
        .category-card {
          height: 100%;
        }

        &--accordion {
          max-width: 100%;
          flex: 0 0 100%;
          overflow: hidden;

          .category-card-accordion {
            .category-card-accordion-image {
              cursor: pointer;
              background: linear-gradient(
                  rgba(30, 79, 199, 0.9),
                  rgba(30, 79, 199, 0.55)
                ),
                url(/s/static/images/categories.png) top;
              height: 80px;
              width: 100%;
              overflow: hidden;
              position: relative;
              &__text {
                font-size: 18px;
                left: 58px;
                top: 13px;
              }
              img {
                transform: translate(-38%, 6px) scaleX(-1);
                width: 170px;
                right: auto;
                left: 0px;
              }
            }
            .category-card-accordion-body {
              transition: 0.5s all ease;
              max-height: 0px;
              padding: 0px 16px;

              h4 {
                color: #6b747f;
                margin: 0px;
              }

              .category-card-accordion-title__icon {
                margin-top: 16px;
                margin-bottom: 8px;
                &:before {
                  background-color: #f5fafa;
                  opacity: 1;
                  z-index: 0;
                }
                span {
                  z-index: 1;
                }
              }
            }
          }

          .category-card-accordion--open {
            .category-card-accordion-body {
              max-height: 500px;
              padding: 16px;
            }
          }
        }
      }
    }

    &__title {
      margin: 2.5rem 0 1.5rem 0;
      text-align: center;
      color: var(--gray-800);
      font-size: 2rem;
      font-weight: 300;
      text-align: left;
      line-height: 120%;

      &.category {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-weight: 300;
        }

        .search__section__category-cta {
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          color: var(--blue);
          position: relative;
          display: flex;
          align-items: center;

          span {
            position: relative;
            width: 41px;
            height: 41px;
            display: flex;
            align-items: center;

            svg {
              margin-left: 12px;
              width: 12px;
              height: 12px;
              position: relative;
              transition: all $timing-5 $ease;
            }

            &:before {
              content: '';
              position: absolute;
              width: 41px;
              height: 41px;
              border-radius: 50%;
              opacity: 0.05;
              background-color: var(--blue);
              left: -2px;
              transition: all $timing-5 $ease;
            }
          }

          &:hover {
            span {
              svg {
                fill: $white;
              }

              &:before {
                transform: scale(1.5);
                opacity: 0.1;
              }
            }
          }
        }
      }
    }

    .slick-list {
      margin-left: -16px;

      @media (max-width: $desktop-m) {
        margin-left: -8px;
      }
    }

    .slick-slide {
      padding: 0 16px;

      @media (max-width: $desktop-m) {
        padding: 0 8px;
      }
    }

    .search__category-search {
      width: 100%;

      @media (min-width: $mobile) {
        min-height: 114px;
      }
    }

    .search__category-suggestions {
      margin-top: 16px;
      width: 100%;
      display: flex;
      justify-content: center;

      .page-container__content {
        @media (max-width: $tablet) {
          max-width: 100%;
          padding-right: 0;
          padding-left: 0;
          .suggestions-list {
            padding: 0 16px;
          }
        }
      }

      @media (max-width: $tablet-max) {
        margin-left: 0;
      }
    }

    .search__productsById {
      width: 100%;
    }

    .search__all-products {
      @media (min-width: 62.75rem) {
        margin-bottom: 2.5rem;
      }
    }

    .search__productsById {
      @media (min-width: $tablet-mid-2) {
        padding-bottom: 2.5rem;
      }
    }

    .search__fixed-banners,
    .search__best-rated,
    .search__category-search,
    .search__newbies,
    .search__all-products,
    .search__productsById {
      &.loading {
        margin-left: -16px;
      }

      .slick-list {
        @media (max-width: $tablet-mid-2) {
          overflow: inherit;
        }
      }
    }

    .search__fixed-banners {
      .slick-list {
        @media (max-width: $tablet-mid-2) {
          margin-left: -0.5rem;
          margin-right: -0.6rem;
        }
      }

      .slick-arrow-buttons {
        bottom: 0;
      }
    }

    .search__category {
      &--main {
        width: 100%;
        min-height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .slick-list {
        .slick-slide {
          .category-card {
            min-width: 394px;
            max-width: 394px;

            @media (max-width: $tablet-max) {
              min-width: 253px;
              max-width: 253px;
              min-height: 412px;
            }

            @media (max-width: $mobile) {
              min-width: 230px;
              max-width: 230px;
              min-height: 412px;
            }
          }
        }

        @media (max-width: $tablet-max) {
          overflow: inherit;
        }
      }

      .slick-arrow-buttons {
        opacity: 1;

        &.left,
        &.right {
          position: absolute;
          z-index: 1;
          bottom: -78px;
        }

        &.left {
          top: auto;
          left: auto;
          right: 96px;
        }

        &.right {
          right: 16px;
          top: auto;
          left: auto;
        }

        &.slick-disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }

        @media (max-width: $tablet-mid-2) {
          display: none;
        }
      }

      &-drawer {
        max-width: 379px;
        width: 100%;
        position: fixed;
        height: 100%;
        z-index: 9999999999;
        top: 0;
        right: 0;
        background-color: $white;
        display: flex;
        flex-direction: column;
        transform: translateX(120%);
        transition: all $ease $timing-8;
        transition-delay: 500ms;

        &.opened {
          transform: translateX(0);
        }

        .category__drawer {
          &-header {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
              padding: 32px;
              font-weight: 500;
              color: var(--blue);
            }

            svg {
              margin-right: 60px;
              width: 21px;
              height: 21px;
              color: var(--blue);
              cursor: pointer;
            }

            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              border-bottom: 1px solid #979797;
              bottom: 0;
              opacity: 0.1;
            }
          }

          &-body {
            display: flex;
            flex-direction: column;
            padding: 32px;
            height: 100%;
            overflow: auto;

            a {
              text-decoration: none;
              cursor: pointer;
              padding-bottom: 24px;
              transition: all $ease $timing-5;
              color: $main-title;
              font-weight: 500;

              &:hover {
                color: var(--blue);
              }
            }
          }

          @media (max-width: $tablet-max) {
            max-width: 100%;
            width: 100%;
          }
        }

        @media (max-width: $tablet-max) {
          max-width: 100%;
          width: 100%;
        }
      }

      &-overlay {
        background-color: #d0d3d0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999999;
        pointer-events: none;
        opacity: 0;
        transition: all $ease $timing-5;

        &.opened {
          opacity: 0.5;
          pointer-events: auto;
        }
      }

      @media (max-width: $tablet-max) {
        padding-bottom: 0;
      }
    }

    .search__producer {
      width: 100%;

      .slick-list {
        @media (max-width: $tablet-max) {
          overflow: inherit;
        }
      }
    }

    .search__all-products {
      width: 100%;

      &-cta {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.25rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0.25rem 0.5rem;
        width: fit-content;
        border-radius: 0.25rem;
        border: 0.0625rem solid var(--blue);
        font-size: 0.75rem;
        color: var(--blue);
        transition: all $timing-5 $ease;
        text-decoration: auto;
        background-color: transparent;

        svg {
          width: 0.75rem;
          height: 0.75rem;
          margin-left: 0.3125rem;
        }

        &:hover {
          border-color: transparent;
          background-color: var(--blue);
          color: var(--white);
        }

        @media (max-width: $tablet-max) {
          margin-bottom: 1.75rem;
        }
      }

      .embla__container {
        .embla__slide__size_4:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .search-jumbotron {
    margin-bottom: 40px;
    position: relative;

    h1 {
      font-size: 40px;
      line-height: 50px;
      font-weight: normal;
      margin-bottom: 16px;
      max-width: 540px;
      width: 100%;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
      max-width: 377px;
      font-weight: normal;
    }
  }

  .search-illustration {
    width: 100%;
    max-width: 577px;
    text-align: right;

    &__image {
      width: 100%;
      max-width: 440px;
    }
  }

  .search-cta {
    margin-top: 24px;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;

    span {
      margin-right: 10px;
    }

    a {
      cursor: pointer;
      transition: all $ease $timing-5;
      text-decoration: underline;
      white-space: nowrap;

      svg {
        width: 16px;
        height: 14px;
        margin-left: 5px;
        transform: translate(0, 0);
        transition: all $ease $timing-5;
      }

      &:hover {
        color: var(--hotmart-primary);

        svg {
          transform: translate(2px, 0);
        }
      }
    }
  }

  #search-bar {
    position: absolute;
    max-width: 700px;
    width: 100%;
  }

  @media screen and (max-width: 1366px) {
    padding: 0;
  }

  @media (min-width: 1920px) {
    padding: 24px;

    .search__banner {
      margin-top: 0px;
    }
  }

  @media (max-width: $tablet-max) {
    .search-jumbotron {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .search-section {
      max-width: initial;

      #hm-mktplace-search-bar {
        max-width: initial;
        width: 100%;
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 100%;

    .search-illustration {
      padding-top: 24px;
    }
  }

  @media (max-width: $tablet-max) {
    .search-jumbotron {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .search-section {
      max-width: initial;

      #hm-mktplace-search-bar {
        max-width: initial;
        width: 100%;
      }
    }
  }

  @media (max-width: $desktop-m) {
    .search__section {
      &__title {
        margin: 2.5rem 0 1rem 0.5rem;
      }
    }
  }

  @media (max-width: $tablet-mid) {
    .search__section {
      &__title {
        margin: 1.5rem 0 1rem 0.5rem;
        text-align: left;

        h2 {
          font-size: 1rem;
          font-weight: normal;
          line-height: 150%;
        }
      }
    }

    &--hide-banner {
      .search__section {
        .search__category-search {
          .search__section__title {
            margin-bottom: 16px;
            margin-top: 16px;
          }
        }
      }
    }

    .search-illustration {
      display: none;
    }

    .search-jumbotron {
      margin-bottom: 24px;

      h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 39px;
      }

      h4 {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

  @media (max-width: $tablet-mid-1) {
    flex-direction: column;
  }

  @media (max-width: 569px) {
    .search-jumbotron {
      h1 {
        width: 100%;
      }
    }
  }

  &__hotmart {
    .search__section {
      align-items: initial;
    }
  }
}

.search-container__advantages {
  background-image: url('/s/static/images/illustration/galaxy_advantages_mobile.jpg');
  background-size: cover;
  margin-top: 2.5rem;

  @media (min-width: $tablet-min) {
    background-image: url('/s/static/images/illustration/galaxy_advantages.svg');
    padding-bottom: 1rem;
  }

  @media (min-width: $desktop-min) {
    padding-bottom: 4rem;
  }

  .search__advantages-cta {
    margin-bottom: 3rem;
  }
}

.search-container__hotmart {
  @media (max-width: $tablet-max) {
    background-color: #fbfbfb;
  }
}

.search__advantages {
  display: flex;
  flex-direction: column;
  width: 100%;

  .page-container__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    max-width: 80rem !important;

    /** Make container 1260px again for screens at least 1281px wide*/
    @media (min-width: 80.0625rem) {
      max-width: 78.75rem !important;
    }
  }
}

.search__advantages {
  &-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 0 1rem;

    @media (min-width: $tablet-mid) {
      margin-bottom: 2rem;
    }
  }

  &-title {
    font-size: 1.4375rem;
    color: var(--gray-800);
    margin-top: 1.5rem;
    margin-bottom: 0.625rem;
    line-height: 120%;
    font-weight: 300;
    width: 100%;

    @media (min-width: $tablet-mid) {
      font-size: 2.5rem;
      margin-top: 2.5rem;
    }
  }

  &-description {
    font-size: 1rem;
    color: var(--gray-800);
    margin-bottom: 0.625rem;
    line-height: 120%;
    font-weight: 300;
    width: 100%;
    padding: 0 2rem;

    @media (min-width: $tablet-mid) {
      font-size: 1.5rem;
      padding: 0;
    }
  }

  &-cta {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    color: var(--white);
    transition: all $timing-5 $ease;
    background-color: var(--blue);
    border: none;
    text-decoration: none;

    a {
      text-decoration: none;
    }

    svg {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.5rem;
    }

    &.desktop {
      @media (max-width: $tablet-max) {
        display: none;
      }
    }

    &.mobile {
      @media (min-width: $tablet-max) {
        display: none;
      }
    }

    &:hover {
      background-color: var(--blue);
      color: white;
    }

    @media (min-width: $tablet-mid) {
      font-size: 1.25rem;
      padding: 0.5rem 1rem;
    }
  }

  &-icons {
    display: flex;
    gap: 0.75rem;
    overflow-x: scroll;
    width: 100vw;
    margin-left: 0.25rem;
    padding-bottom: 1rem;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $desktop-min) {
      justify-content: center;
      gap: 2rem;
      margin-left: 0;
    }
  }

  &-card {
    width: 17rem;
    min-width: 16rem;
    height: 13.75rem;
    border-radius: 0.375rem;
    box-shadow: var(--box-shadow);
    background-color: var(--white);
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-of-type {
      margin-left: 1.5rem;
    }

    &:last-of-type {
      margin-right: 1.5rem;
    }

    @media (min-width: $tablet-mid) {
      padding: 2rem 1.5rem;
    }

    @media (min-width: 61.25rem) {
      min-width: auto;
    }

    &__icons {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      position: relative;
    }

    &__dashes {
      display: flex;
      gap: 0.25rem;
      position: absolute;
      right: 2rem;

      &__item {
        height: 0.125rem;
        width: 0.625rem;
        border-radius: 0.375rem;
        background-color: var(--gray-300);
      }
    }

    &__icon-big {
      display: grid;
      place-items: center;
      width: 4rem;
      height: 4rem;
      background-color: var(--purple);
      border-radius: 50%;
      margin-right: 1rem;
      z-index: 1;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--white);
      }
    }

    &__icon-small {
      display: grid;
      place-items: center;
      width: 2.25rem;
      height: 2.25rem;
      background-color: var(--brand-primary);
      border-radius: 50%;
      z-index: 1;

      svg {
        color: var(--white);
        width: 1rem;
        height: 1rem;
      }
    }

    &__body {
      display: flex;
      align-items: center;
      text-align: center;

      div {
        display: flex;
        font-size: 0.875rem;
        line-height: 1.375rem;
        font-weight: bold;
        margin: 0;
        letter-spacing: 0;

        @media (min-width: $tablet-mid) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }

    &__footer {
      div {
        font-size: 0.75rem;
        line-height: 150%;
        color: var(--gray-500);
        text-align: center;
      }
    }
  }
}

.search__fixed-banners {
  width: 100%;

  &__wrapper {
    display: flex;
    gap: 2rem;

    &-active {
      margin-top: 2.5rem;

      .slick-dots {
        margin-top: 0;
      }

      .slick-arrow-buttons {
        bottom: -1rem !important;
      }

      @media (min-width: 49.8125rem) {
        margin-top: 2.5rem;
      }

      @media (max-width: 49.875rem) {
        padding-bottom: 0.75rem;
      }

      @media (min-width: 49.875rem) and (max-width: 62.75rem) {
        padding-bottom: 1rem;
      }
    }
  }

  @media (max-width: $tablet-max) {
    &__wrapper {
      flex-direction: column;
      gap: 0;
    }
  }
}

.search__most-accessed {
  width: 100%;

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -0.25rem;

    @media (min-width: $tablet-mid-1) {
      padding: 0 1rem;
      justify-content: flex-end;
    }

    @media (min-width: $desktop) {
      padding: 0;
    }
  }

  &__selected {
    margin-top: 1rem;

    @media (min-width: $mobile-l) {
      margin-left: 1.5rem;
    }
  }

  @media (min-width: $tablet-mid-2) {
    &__tags {
      margin-left: 0;
    }

    h2 {
      margin: 2.5rem 0 1rem 0 !important;
    }
  }

  .suggestions-list {
    &__content {
      flex-wrap: wrap;
    }

    &--horizontal {
      margin: 0;
    }

    &__tag {
      border: none;
      padding: 0.375rem 1rem;
      margin: 0.25rem;
      border-radius: 3rem;

      &__body {
        font-size: 1rem;
        margin-right: 0;
        padding: 0;
      }

      a {
        text-decoration: none;
      }

      &.hot-tag {
        &--light {
          border: 1px solid var(--blue) !important;
          color: var(--blue) !important;

          &.hot-tag--active {
            background: var(--blue) !important;
            color: var(--white) !important;
          }
        }
      }
    }

    @media (max-width: $mobile-l) {
      &__content {
        overflow-x: scroll;
        width: 450px;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__suggestion__link {
        &:first-child {
          margin-left: 1rem;
        }

        &:last-child {
          margin-right: 2rem;
        }
      }
    }
  }
}

.search__all-products,
.search__best-rated {
  @media (max-width: $tablet) {
    .glider-slide {
      max-width: 300px !important;
    }
  }

  @media (min-width: $tablet) {
    .glider-slide {
      max-width: inherit;
    }
  }
}

.search__sell {
  &__title {
    font-weight: bold;
    font-size: 1rem;
    width: 294px;
  }

  &__description {
    font-size: 0.8rem;
    margin: 0.5rem 1rem 1rem;
  }

  &__login {
    width: 95%;
    margin-top: 1rem;
    margin-left: 1.2rem;
    text-decoration: none;

    &-desktop {
      display: none;
    }
  }

  .page-container__content {
    padding-right: 0;
  }

  .embla__container {
    hot-card:first-child {
      background-color: var(--yellow-lightest);
    }

    hot-card:nth-child(2) {
      background-color: var(--cyan-lightest);
    }

    hot-card:nth-child(3) {
      background-color: var(--green-lightest);
    }
  }

  &__section {
    padding: 1rem 1rem 2rem;
    box-shadow: var(--box-shadow);
    border-radius: 0.375rem;
  }

  &__card {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.3rem;
    margin: 0.5rem 0;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    height: 80px;
  }

  &__info {
    width: auto;
    min-height: 130px;
  }

  &__image {
    width: 180px;
    height: 120px;
    object-fit: contain;
  }

  .glider-slide {
    width: 250px !important;
    margin-right: 1rem;
  }

  .slick-slider {
    width: 100%;
  }

  @media (min-width: $desktop) {
    &__container {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      max-width: inherit;
      padding-top: 2rem;

      .glider {
        flex: 2;

        .glider-track {
          display: flex;
          justify-content: space-around;

          .glider-slide {
            width: auto !important;
            margin-right: 0;
          }
        }
      }
    }

    &__title {
      font-size: 1.5rem;
    }

    &__info {
      width: 262px;
    }

    &__section {
      padding: 1rem;
    }

    &__image {
      width: 240px;
      height: 162px;
    }

    &__description {
      font-weight: normal;
      font-size: 1rem;
      margin: 0.5rem 0 1.2rem;
      width: 318px;
    }

    &__login {
      display: none;

      &-desktop {
        display: initial;
      }
    }
  }

  @media (max-width: $tablet) {
    &__container {
      .glider-track {
        max-width: 900px !important;
      }
    }

    &__login {
      width: 90%;
    }
  }

  @media (max-width: $desktop-m) {
    margin-top: -18px;

    &__container {
      align-items: flex-start;
      flex-direction: column;

      & > div {
        margin-top: 1rem;
        width: 100%;
        text-align: center;
      }
    }

    &__description,
    &__title {
      width: auto;
    }
  }

  @media (max-width: $tablet-mid) {
    &__container {
      & > div {
        width: auto;
      }
    }
  }

  @media (min-width: $tablet-min) and (max-width: $desktop-m) {
    &__container {
      .embla__viewport {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.search__categories-tabs {
  width: 100%;

  .slick-slider {
    margin-top: 1.5rem;
  }

  .slick-arrow-buttons {
    &.left,
    &.right {
      bottom: -3.75rem;
    }
  }

  button {
    cursor: pointer;
    border: 0.0625rem solid var(--blue);
    border-radius: 0.25rem;
    background-color: transparent;
    color: var(--blue);
    font-size: 0.75rem;
    line-height: 150%;
    padding: 0.25rem 1rem;
    margin-top: 2rem;

    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
}

.search__category-search {
  .search__section__title {
    text-align: center;
  }
}

.search__best-rated {
  width: 100%;
  margin-bottom: 1rem;

  @media (max-width: $desktop-m) {
    margin-left: 0;
  }

  #best-rated-card-signup {
    display: none;
  }
}

.search__hotmart-app {
  width: 100%;
  margin-left: 0.5rem;

  .slick-slider {
    margin-top: 1.5rem;
  }

  .slick-dots {
    margin-left: 0;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: normal;
    line-height: 150%;
    color: var(--gray-800);
    max-width: 48.375rem;
  }

  &__download {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
  }

  &__store-button {
    display: grid;
    place-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.0625rem solid var(--gray-200);
    border-radius: 50%;
    cursor: pointer;

    &__apple {
      display: grid;
      place-items: center;
      margin-top: -0.0625rem;
    }

    &__googleplay {
      display: grid;
      place-items: center;
      margin-left: 0.25rem;
    }

    &:hover {
      box-shadow: 0 0 0.625rem 0 rgba(84, 91, 100, 0.2);
    }

    &:focus {
      border: 0.125rem solid var(--blue);
    }

    &:first-of-type {
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
  }
}
